import { Vue } from 'vue-property-decorator';
import moment from 'moment';

Vue.filter('toCurrencyy', (value: number) => {
  if (!value) {
    return '0,00';
  }

  const val = (value / 1).toFixed(2).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

Vue.filter('formatDate', function(value: string) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});

Vue.filter('formatDateNoTime', function(value: string) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('toCurrency', function (value: number) {
  var formatter = new Intl.NumberFormat('eu-EU', {
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(value);
});


