import {Machine} from "@/models/machine";
import {Customer} from "@/models/customer";
import moment from "moment/moment";

export class Station {

    public id: string;
    public name!: string;
    public contactPerson!: string;
    public street!: string;
    public houseNo!: string;
    public zipCode!: string;
    public city!: string;
    public country!: string;
    public phone!: string;
    public mail!: string;
    public inventoryActive!: boolean;
    public maintenanceActive!: boolean;
    public machines!: Machine[];
    public customer!: Customer | string;

    public nextMaintenance!: string;
    public nextMaintenanceDate?: Date;

    public geoPosition: number[] = [];

    constructor(stationData: any) {
        Object.assign(this, stationData);

        this.id = stationData.id;

        if (stationData.machines) {
            this.machines = stationData.machines.map((mach: any) => new Machine(mach));
        } else {
            this.machines = [];
        }

        if (stationData.nextMaintenance) {
            this.nextMaintenanceDate = moment(stationData.nextMaintenance, 'YYYY-MM-DD').toDate();
        }

        if (stationData.customer && typeof stationData.customer != "string") {
            this.customer = new Customer(stationData.customer);
        } else {
            this.customer = stationData.customer;
        }
    }
}
