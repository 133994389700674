import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify);

export default new Vuetify({
  options: {
    customProperties: true
  },
  theme: {
    themes: {
      light: {
        primary: '#3eb286',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        errorLite: '#ffa6a6',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        pickingArticle: '#b8cce4',
        valveArticle: '#D3F8D3',
        bookingListArticle: '#a595ff',
        button: '#2F4858'
      }
    }
  },
  lang: {
    locales: { de },
    current: 'de'
  }
});
