






























































































import {Component, Vue} from 'vue-property-decorator';
import {Station} from "@/models/station";
import {Customer} from "@/models/customer";
import {namespace} from "vuex-class";
import L from 'leaflet';
import moment from "moment";
import {Machine} from "@/models/machine";

export enum FilterType {
  INVENTORY, MAINTENANCE, ALL
}

const StationStore = namespace('station');

@Component({
  components: {
    MaintenanceDialogComponent: () => import('./../components/dialog/MaintenanceDialog.component.vue'),
    InventoryDialogComponent: () => import('./../components/dialog/InventoryDialog.component.vue'),
  }
})
export default class MapView extends Vue {

  @StationStore.Action('loadAllStations')
  public loadAllStations!: () => Promise<Station[]>;

  @StationStore.Getter('allStations')
  public allStations!: Station[];

  public markerStations: Station[] = []

  public url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  public markerSize = [32, 37];
  public clusterOptions = {
    disableClusteringAtZoom: 14
  };

  public maintenanceCount = 0;
  public inventoryStations: Station[] = [];
  public maintenanceStations: Station[] = []

  public FilterType = FilterType;
  public filterType: FilterType = FilterType.ALL;

  public showMaintenanceDialog = false;
  public showInventoryDialog = false;

  public zoom = 8;
  public center = [53.073635, 8.806422];

  public async mounted() {
    await this.loadAllStations();

    /*
    if (this.allStations && this.allStations.length === 1 && this.allStations[0].geoPosition && this.allStations[0].geoPosition.length > 0) {
      this.center = [this.allStations[0].geoPosition[1], this.allStations[0].geoPosition[0]];
      this.zoom = 16;
    }
*/
    this.maintenanceStations = this.allStations.filter(station => this.getMaintenanceMachines(station).length > 0);
    this.maintenanceCount = this.allStations.map(station => this.getMaintenanceMachines(station).length)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    this.inventoryStations = this.allStations.filter(station => station.inventoryActive);
    this.markerStations = this.allStations;
  }

  public zoomUpdated(zoom: number) {
    this.zoom = zoom;
    if (zoom <= 14) {
      this.markerSize = [16, 20];
    } else {
      this.markerSize = [32, 37];
    }
  }

  public setFilter(filterType: FilterType) {
    if (this.filterType === filterType) {
      this.filterType = FilterType.ALL;
      this.markerStations = this.allStations;
    } else if (filterType === FilterType.INVENTORY) {
      this.filterType = filterType;
      this.markerStations = this.allStations.filter(station => station.inventoryActive);
    } else if (filterType === FilterType.MAINTENANCE) {
      this.filterType = filterType;
      this.markerStations = this.allStations.filter(station => this.getMaintenanceMachines(station).length > 0);
    } else if (filterType === FilterType.ALL) {
      this.filterType = filterType;
      this.markerStations = this.allStations;
    }
  }

  public getIcon(station: Station) {
    if (station.inventoryActive && this.filterType !== FilterType.MAINTENANCE) {
      return new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    } else if (this.getMaintenanceMachines(station).length > 0) {
      return new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-gold.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    }
      else {
      return new L.Icon({
        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    }
  }

  public openDetails(station: Station) {
    this.$router.push({name: 'station_details_view', params: {customerId: (station.customer as Customer).id, stationId: station.id}});
  }

  public getMaintenanceMachines(station: Station): Machine[] {
    const currentDate = moment().endOf('day');
    const machines = station.machines.filter(machine => {
      const nextMaintenance = machine.nextMaintenance ? moment(machine.nextMaintenance, 'YYYY-MM-DD').toDate() : null;
      return nextMaintenance && nextMaintenance <= currentDate.toDate() && machine.maintenanceActive;
    });
    return machines;
  }
}

