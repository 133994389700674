import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import {Station} from "@/models/station";
import {Machine} from "@/models/machine";

export default class MachineRepository extends EntityBaseRepository {
    public async getStationMachines(stationId: string, isMaintenance: boolean): Promise<AxiosResponse> {
        const machines: any = await this.axiosClient.get(`machine?station=${stationId}${ isMaintenance ? `&isMaintenance=${isMaintenance}` : ""}`);
        return machines;
    }

    public createMachine(station: Station, machine: Machine): Promise<AxiosResponse> {
        return this.axiosClient.post(`machine?station=${station.id}`, machine);
    }

    public loadMachine(machineId: string): Promise<AxiosResponse> {
        return this.axiosClient.get(`machine/${machineId}`);
    }

    public updateMachine(machine: Machine): Promise<AxiosResponse> {
        const machineCopy = Object.assign({}, machine);
        delete machineCopy.imageMontageansicht;
        delete machineCopy.imageTypenschild;
        delete machineCopy.imageSteuerung;
        delete machineCopy.imageBauteileSchrank;
        delete machineCopy.imageDefekteBauteile;

        return this.axiosClient.patch(`/machine/${machineCopy.id}`, machineCopy);
    }

    public deleteMachine(machineId: string) :Promise<AxiosResponse> {
        return this.axiosClient.delete(`/machine/${machineId}`);
    }

    public getChecklistPdf(machineIds: any[], station: any, withImage: boolean): Promise<any> {
        let payload = [];

        for(let i = 0; i < machineIds.length; i++) {
            payload.push(machineIds[i].id);
        }

        const pdf = {
            data: payload,
            station: station.id,
            withImage: withImage
        }
        return this.axiosClient.get(`machine/pdf/${JSON.stringify(pdf)}`,  { responseType: 'arraybuffer' });
    }

}
