import AuthRepository from '@/api/AuthRepository';
import UserRepository from '@/api/UserRepository';
import CustomerRepository from "@/api/CustomerRepository";
import StationRepository from "@/api/StationRepository";
import MachineRepository from "@/api/MachineRepository";
import MaintenanceRepository from "@/api/MaintenanceRepository";
import ImageRepository from "@/api/ImageRepository";
import DocumentPdfRepository from "@/api/DocumentPdfRepository";

const repositories: any = {
  auth: { Repository: AuthRepository, instance: null },
  user: { Repository: UserRepository, instance: null },
  customer: { Repository: CustomerRepository, instance: null },
  station: { Repository: StationRepository, instance: null },
  machine: { Repository: MachineRepository, instance: null },
  maintenance: { Repository: MaintenanceRepository, instance: null },
  image: { Repository: ImageRepository, instance: null },
  documentpdf: { Repository: DocumentPdfRepository, instance: null },
};
/**
 *  Factory to create repositories. All created repositories are singleton instances.
 */
export const RepositoryFactory = {
  get: (name: string): any => {
    if (repositories[name].instance) {
      return repositories[name].instance;
    } else {
      return new repositories[name].Repository();
    }
  }
};
