




































import {Component, Prop, Vue} from "vue-property-decorator";

@Component({})
export default class HeaderInformation extends Vue {

  @Prop({ default: () => {} })
  public content!: any;

  @Prop({ default: 'Titel' })
  public title!: string;

}

