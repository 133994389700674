import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import {Customer} from "@/models/customer";
import {Station} from "@/models/station";

export default class StationRepository extends EntityBaseRepository {
    public getAllStations(): Promise<AxiosResponse> {
        return this.axiosClient.get(`station`);
    }

    public getStationCount(): Promise<AxiosResponse> {
        return this.axiosClient.get(`station/helper/count`);
    }

    public getStation(stationId: String): Promise<AxiosResponse> {
        return this.axiosClient.get(`/station/${stationId}`);
    }

    public getCustomerStations(customer: Customer, isMaintenance?: boolean): Promise<AxiosResponse> {
        return this.axiosClient.get(`station?customer=${customer.id}${ isMaintenance ? "&isMaintenance=true" : "" }`);
    }

    public createStation(customer: Customer, station: Station): Promise<AxiosResponse> {
        return this.axiosClient.post(`station?customer=${customer.id}`, station);
    }

    public updateStation(station: Station): Promise<AxiosResponse> {
        const payload = Object.assign({}, station);

        delete payload.machines;

        payload.customer = (station.customer as Customer).id;
        return this.axiosClient.patch(`/station/${station.id}`, payload);
    }

    public deleteStation(id: string): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/station/${id}`);
    }



    public generateOffer(station: Station): Promise<any> {
        let payload = station.id;

        const pdf = {
            data: payload,
            station: station.id
        }
        return this.axiosClient.get(`station/pdf/${payload}`,  { responseType: 'arraybuffer' });
    }
}
