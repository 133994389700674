import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import { RootState, UserState } from '@/store/storeStateInterface';
import UserRepository from '@/api/UserRepository';
import User from '@/models/user';
import AppUser from '@/models/appUser';

const store: UserState = {
    users: [],
    appUsers: []
};
const userRepository: UserRepository = RepositoryFactory.get('user');

const actions: ActionTree<UserState, RootState> = {
    loadAllUsers: async ({ commit }): Promise<User[]> => {
        const response = await userRepository.getAllUsers();
        const users = response.data.map((data: any) => new User(data));
        commit('setUsers', users);
        return users;
    },
    loadAllAppUsers: async ({ commit }): Promise<AppUser[]> => {
        const response = await userRepository.getAllAppUsers();
        const users = response.data.map((data: any) => new AppUser(data));
        commit('setAppUsers', users);
        return users;
    }
};

const mutations: MutationTree<UserState> = {
    setUsers: (state: UserState, users: any[]) => {
        state.users = users;
    },
    setAppUsers: (state: UserState, users: AppUser[]) => {
        state.appUsers = users;
    }
};

const getters: GetterTree<UserState, RootState> = {
    users: (state: UserState) => state.users,
    appUsers: (state: UserState) => state.appUsers
};

const userStore: Module<UserState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default userStore;
