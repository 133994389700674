import {ChecklistElement} from "@/models/checklistElement";
import {MachineImage} from "@/models/machineImage";
import AppUser from "@/models/appUser";
import {MaintenanceListElement} from "@/models/maintenanceElement";
import {Machine} from "@/models/machine";

export enum MachineImageType {
  imageMaintenance = "imageMaintenance",
  imageMontageansicht = "imageMontageansicht",
  imageSteuerung = "imageSteuerung",
  imageTypenschild = "imageTypenschild",
  imageBauteileSchrank = "imageBauteileSchrank",
  imageDefekteBauteile = "imageDefekteBauteile"
}

export class Maintenance {

  public id: string;
  public productionNr!: string;
  public flapNr!: string;
  public maintenanceDate!: string; // date
  public comment!: string;
  public appUser!: AppUser;
  public machine!: Machine;

  public createdAt!: string;
  public updatedAt!: string;

  public maintenanceImages: MachineImage[] = [];
  public maintenanceImagesNotRequired!: boolean;
  public imageMaintenanceComment!: string;
  public maintenanceList!: MaintenanceListElement[];

  constructor(maintenenaceData: any) {
    this.id = maintenenaceData._id;
    Object.assign(this, maintenenaceData);

    if (maintenenaceData.id) {
      this.id = maintenenaceData.id;
    }

    if (maintenenaceData.appUser) {
      this.appUser = new AppUser(maintenenaceData.appUser);
    }

    if (maintenenaceData.machine) {
      this.machine = new Machine(maintenenaceData.machine);
    }

    if (maintenenaceData.maintenanceImages) {
      this.maintenanceImages = maintenenaceData.maintenanceImages.map((data: any) => new MachineImage(data, MachineImageType.imageMaintenance));
    }

    if (maintenenaceData.maintenanceList) {
      if (typeof maintenenaceData.maintenanceList == "string") {
        const checklistData = JSON.parse(maintenenaceData.maintenanceList);
        this.maintenanceList = checklistData.map((element: any) => new MaintenanceListElement(element));
      } else {
        this.maintenanceList = maintenenaceData.maintenanceList;
      }
    }
  }

  get hasImages(): boolean {
    return this.maintenanceImages && this.maintenanceImages.length > 0;
  }
}
