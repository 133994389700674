import store from './../store';

export class RoutingPermissions {

    public static async checkAccess(to: any, from: any, next: any) {
        if (store.getters['auth/token']) {
            next();
        } else {
            next({ name: 'login_view' });
        }
    }
}
