import EntityBaseRepository from '@/api/EntityBaseRepository';
import {AxiosResponse} from 'axios';
import {Maintenance} from "@/models/maintenance";

export default class MaintenanceRepository extends EntityBaseRepository {
    public updateMaintenance(maintenance: Maintenance): Promise<AxiosResponse> {
        const maintenanceCopy = Object.assign({}, maintenance);
        delete maintenanceCopy.maintenanceImages;
        return this.axiosClient.patch(`/maintenance/${maintenance.id}`, maintenanceCopy);
    }

    public deleteMaintenance(maintenanceId: string): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/maintenance/${maintenanceId}`);
    }

    public generatePDF(maintenanceId: string, options: any): Promise<any> {
        return this.axiosClient.get(`maintenance/${maintenanceId}/generatePDF${options.withImages ? "?withImages=true&" : "?withImages=false&"}${options.language === 'de' ? "language=de" : "language=en"}`,
            { responseType: 'arraybuffer' });
    }

    public generateMultiplePDF(maintenances: string[], options: any): Promise<any> {
        return this.axiosClient.get(`maintenance/${JSON.stringify(maintenances)}/generateMultiplePDF${options.withImages ? "?withImages=true" : "?withImages=false"}&language=${options.language}`, {
            responseType: 'arraybuffer'
        });


    }


}
