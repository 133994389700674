































































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Customer} from "@/models/customer";
import {Station} from "@/models/station";
import {Machine} from "@/models/machine";
import moment from "moment/moment";

const CustomerStore = namespace('customer');
const StationStore = namespace('station');
const MachineStore = namespace('machine');


@Component({
  components: {
    EditStationComponent: () => import('./../components/EditStation.component.vue'),
    EditCustomerComponent: () => import('./../components/EditCustomer.component.vue'),
  }
})
export default class MaintenanceToolView extends Vue {

  @MachineStore.Action("createMachine")
  public createMachine!: (payload: { station: Station, machine: Machine }) => Promise<Machine>

  @CustomerStore.Action('loadAllCustomers')
  public loadAllCustomers!: (payload: { isMaintenance: boolean }) => Promise<Customer[]>;

  @StationStore.Action('loadAllStations')
  public loadAllStations!: (payload: { customer: Customer }) => Promise<Station[]>

  @StationStore.Action('loadCustomerStations')
  public loadCustomerStations!: (payload: { customer: Customer, isMaintenance: boolean }) => Promise<Station[]>

  @Watch('loading')
  loadingBalken(value: string) {
    if (!value) return
    setTimeout(() => (this.loading = false), 3000)
  }


  public customers: Customer[] = [];
  public customer?: Customer = undefined;

  public stations: Station[] = [];
  public showAddCustomerDialog: boolean = false;
  public showAddStationDialog: boolean = false;

  public actuatorItems: string[] = ['SEHAZ', 'EHAZ', 'SEHAZc', 'GEHAZ', 'GHAZ', 'SAE', 'PAZ', 'GAZ', 'HAZ', 'ZEHAZ', 'MHAZ', 'EHAZ-R', 'GHAZ2', 'SEHAZ-R', 'DEHAZ'];
  public actuatorOptions = [{text: "NEU", value: "NEW"}, {text: "ALT", value: "OLD"}];
  public selectedActuator: string = '';
  public selectedStation: Station = {
    id: "",
    name: "",
    contactPerson: "",
    street: "",
    houseNo: "",
    zipCode: "",
    city: "",
    country: "",
    phone: "",
    mail: "",
    inventoryActive: false,
    maintenanceActive: false,
    nextMaintenance: "",
    nextMaintenanceDate: new Date(),
    machines: [],
    customer: "",
    geoPosition: []
  }

  public selectedCustomer: Customer = {
    stations: [],
    id: "",
    name: "",
    contactPerson: "",
    street: "",
    houseNo: "",
    zipCode: "",
    city: "",
    country: "",
    phone: "",
    mail: "",
    geoPosition: []
  };

  public snackbarUpload: boolean = false;
  public text: string = '';
  public timeout: number = 2000;
  public snackbarColor: string = 'green'
  public loading: boolean = false;
  public showGallery: boolean = false;
  public step: number = 1;

  currentTitle () {
    switch (this.step) {
      case 1: return 'Auswahl der importierenden Daten'
      case 2: return 'Einfügen der Daten'
      default: return 'Antriebsübersicht'
    }
  };

  public machine: Machine = new Machine({});

  /**
   * Kalender - letzte Wartung
   */
  public menu: boolean = false;

  public entryText: string = '101197HA\t3\t527-XV-6812B\tPAZ-A-1-125-SR\t4\t\t'; //'101197HA\t\t527-XV-6812B\tPAZ-A-1-125-SR\t4\t\tx';

  public async addEntriesClicked() {
    this.loading = true;
    const parsedItems: any[] = [];

    if (this.entryText) {
      const parsedTextArray = this.entryText.split('\n');
      parsedTextArray.forEach((textItem) => {

        const splittedItem = textItem.split('\t').map(
            x => x
        );
        if(splittedItem[0] === '') {
          splittedItem[0] = '-'
        }
        if(splittedItem[1] === '') {
          splittedItem[1] = '-'
        }
        if(splittedItem[2] === '') {
          splittedItem[2] = '-'
        }

        parsedItems.push(splittedItem);
      });
    }

    let savedAll = true;
    for (let i = 0; i < parsedItems.length; i++) {
      const array = parsedItems[i];
      this.machine.orderNo = array[0];
      this.machine.serialNo = array[1];
      this.machine.tagNo = array[2];
      this.machine.actuatorUnit = array[3];
      this.machine.inspectionYears = array[4];
      try {
        await this.createMachine({station: this.selectedStation, machine: this.machine});
      } catch (e) {
        savedAll = false;
        this.loading = false;

        if(e.status === 500 && e.code === 409) {
          this.$notify({
            group: 'fahlke',
            title: 'Fehler',
            text: 'Machine already exists',
            type: 'error'
          });
        }

        if(e.status === 500 && e.code === 'E_INVALID_CRITERIA') {
          this.$notify({
            group: 'fahlke',
            title: 'Fehler',
            text: 'Eingabewerte fehlerhaft',
            type: 'error'
          });
        }
      }
    }
    if(savedAll) {
      this.loading = false;
      this.snackbarColor = 'green'
      this.snackbarUpload = true;
      this.text = 'Erfolgreich gespeichert';
      this.entryText = '';
    }



  }

  public async mounted() {
    this.customers = await this.loadAllCustomers({isMaintenance: false});
  }

  public async updateStation() {
    try {
      this.stations = await this.loadCustomerStations({customer: this.selectedCustomer, isMaintenance: false});
      this.machine.actuator = this.actuatorItems[0];
    } catch (e) {
      console.log('error', e)
    }
  }

  public changeCustomer(item: any) {
    this.selectedCustomer = item;
    this.updateStation();
  }

  public changeStation() {
    this.selectedActuator = this.actuatorItems[0];
  }

  public openAddCustomerDialog() {
    this.showAddCustomerDialog = true;
  }

  public async closeCustomerDialog() {
    this.showAddCustomerDialog = false;
    this.customers = await this.loadAllCustomers({isMaintenance: false});
  }

  public openAddStationDialog() {
    this.showAddStationDialog = true;
  }

  public async closeStationDialog(reloadData: boolean) {
    if (reloadData) {
      this.stations = await this.loadCustomerStations({customer: this.selectedCustomer, isMaintenance: false});
    }
    this.showAddStationDialog = false;
  }

  public formattedDate() {
    if (this.machine.lastMaintenance) {
      return moment(this.machine.lastMaintenance).format('DD.MM.YYYY');
    }

  }

  public openGallery() {
    this.showGallery = true;
  }


}

