import {ChecklistElement} from "@/models/checklistElement";
import {MachineImageType} from "@/models/maintenance";

export class MachineImage {
  public id!: string;
  public image!: string;
  public imageType!: MachineImageType;

  constructor(imageData: any, type: MachineImageType) {
    this.id = imageData._id;
    this.imageType = type;
    Object.assign(this, imageData);

    if (imageData.id) {
      this.id = imageData.id;
    }
  }
}
