import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {RootState, MachineState} from '@/store/storeStateInterface';
import MachineRepository from "@/api/MachineRepository";
import {Machine} from "@/models/machine";
import {Station} from "@/models/station";
import {Customer} from "@/models/customer";

const store: MachineState = {
    stationMachines: [],
    allMachines: []
};
const machineRepository: MachineRepository = RepositoryFactory.get('machine');

const actions: ActionTree<MachineState, RootState> = {
    loadStationMachines: async ({ commit }, payload: { station: Station, isMaintenance: boolean }): Promise<Machine[]> => {
        const response = await machineRepository.getStationMachines(payload.station.id, payload.isMaintenance);
        const machines = response.data.map((data: any) => new Machine(data));
        commit('setCustomerMachines', machines);
        return machines;
    },
    createMachine: async ({ commit }, payload: { station: Station, machine: Machine }): Promise<Machine> => {
        const response = await machineRepository.createMachine(payload.station, payload.machine);
        return new Machine(response.data);
    },
    loadMachine: async ({ commit }, payload: { id: string }): Promise<Machine> => {
        const response = await machineRepository.loadMachine(payload.id);
        return new Machine(response.data);
    },
    updateMachine: async ({ commit }, payload: { machine: Machine }): Promise<Machine> => {
        console.log(payload.machine)
        const response = await machineRepository.updateMachine(payload.machine);
        return new Machine(response.data);
    },
    deleteMachine: async ({ commit }, payload: { id: string }): Promise<Machine> => {
        const response = await machineRepository.deleteMachine(payload.id);
        return response.data;
    }
};

const mutations: MutationTree<MachineState> = {
    setCustomerMachines: (state: MachineState, machines: any[]) => {
        state.stationMachines = machines;
    },
    setAllMachines: (state: MachineState, machines: any[]) => {
        state.allMachines = machines;
    }
};

const getters: GetterTree<MachineState, RootState> = {
    stationMachines: (state: MachineState) => state.stationMachines,
    allMachines: (state: MachineState) => state.allMachines,
};

const machineStore: Module<MachineState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default machineStore;
