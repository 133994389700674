import {MachineImageType} from "@/models/maintenance";

export enum MachinePdfType {
  pdfElektro = "pdfElektro-Plan",
  pdfFluid = "pdfFluid-Plan",
  pdfPruefliste = "pdfPruefliste",
}


export class MachinePdf {
  public id!: string;
  public pdf!: string;

  public pdfType!: MachinePdfType;

  constructor(pdfData: any) {
    this.id = pdfData._id;

    Object.assign(this, pdfData);

    if (pdfData.id) {
      this.id = pdfData.id;
    }
  }
}
