export default class User {

    public id: string;
    public firstname: string;
    public lastname: string;
    public username: string;
    public permissions: string[];
    public detailPermissions: string[];

    constructor(userData: any) {
        this.id = userData._id;
        if (userData.id) {
            this.id = userData.id;
        }
        this.firstname = userData.firstname;
        this.lastname = userData.lastname;
        this.username = userData.username;
        this.permissions = userData.permissions;
        this.detailPermissions = userData.detailPermissions;
    }
}
