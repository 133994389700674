import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {RootState, StationState} from '@/store/storeStateInterface';
import StationRepository from "@/api/StationRepository";
import {Station} from "@/models/station";
import {Customer} from "@/models/customer";

const store: StationState = {
    customerStations: [],
    allStations: [],
    selectedStation: undefined
};
const stationRepository: StationRepository = RepositoryFactory.get('station');

const actions: ActionTree<StationState, RootState> = {
    loadStation: async ({ commit }, payload: { id: string }): Promise<Station> => {
        const response = await stationRepository.getStation(payload.id);
        return new Station(response.data);
    },
    loadCustomerStations: async ({ commit }, payload: { customer: Customer, isMaintenance?: boolean }): Promise<Station[]> => {
        const response = await stationRepository.getCustomerStations(payload.customer, payload.isMaintenance);
        const stations = response.data.map((data: any) => new Station(data));
        commit('setCustomerStations', stations);
        return stations;
    },
    loadAllStations: async ({ commit }): Promise<Station[]> => {
        const response = await stationRepository.getAllStations();
        const stations = response.data.map((data: any) => new Station(data));
        commit('setAllStations', stations);
        return stations;
    },
    loadStationCount: async ({ commit }): Promise<number> => {
        const response = await stationRepository.getStationCount();
        return response.data.stationCount;
    },
    createStation: async ({ commit }, payload: { customer: Customer, station: Station }): Promise<Station> => {
        const response = await stationRepository.createStation(payload.customer, payload.station);
        return new Station(response.data);
    },
    updateStation: async ({ commit }, payload: { station: Station }): Promise<Station> => {
        const response = await stationRepository.updateStation(payload.station);
        return new Station(response.data);
    },
    deleteStation: async ({ commit }, payload: { id: string }) => {
        const response = await stationRepository.deleteStation(payload.id);
        //return new Station(response.data);
    },

};

const mutations: MutationTree<StationState> = {
    setCustomerStations: (state: StationState, stations: any[]) => {
        state.customerStations = stations;
    },
    setAllStations: (state: StationState, stations: any[]) => {
        state.allStations = stations;
    },
    setSelectedStation: (state: StationState, station: Station) => {
        state.selectedStation = station;
    }
};

const getters: GetterTree<StationState, RootState> = {
    customerStations: (state: StationState) => state.customerStations,
    allStations: (state: StationState) => state.allStations,
    selectedStation: (state: StationState) => state.selectedStation
};

const stationStore: Module<StationState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default stationStore;
