











import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({})
export default class App extends Vue {

  constructor() {
    super();
    document.title = process.env.VUE_APP_TITLE!;
    console.info(`%cclient version: ${process.env.VUE_APP_VERSION}`, 'font-weight: bold');
  }
}
