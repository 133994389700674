












































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import User from '@/models/user';

const AuthStore = namespace('auth');
const UserStore = namespace('user');

@Component({})
export default class LoginView extends Vue {

  @AuthStore.Action('loginAction')
  public loginAction!: (payload: { username: string, password: string }) => Promise<User>;

  @AuthStore.Action('refreshTokenAction')
  public refreshTokenAction!: (payload: { token: string }) => Promise<User>;

  @AuthStore.Mutation('setToken')
  public setToken!: (token: string | undefined) => void;

  @AuthStore.Getter('lastUsername')
  public lastUsername!: string;

  @AuthStore.Getter('token')
  public token?: string;

  @UserStore.Action('loadAllUsers')
  public loadAllUsers!: () => Promise<User[]>;

  @UserStore.Getter('users')
  public users!: User[];

  public isLoading: boolean = false;

  /**
   *  Login data model
   */
  public loginData: { username: string, password: string } = {
    username: '',
    password: ''
  };

  public async mounted() {
    this.isLoading = true;

    if (this.lastUsername) {
      this.loginData.username = this.lastUsername;
    }
    try {
      await this.loadAllUsers();

      if (this.token) {
        await this.refreshTokenAction({ token: this.token });
        await this.$router.push({ name: 'home_view', query: { defaultRoute: 'true' } });
        return;
      } else {
        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
      this.setToken(undefined);
      this.$notify({
        group: 'fahlke',
        title: 'Info',
        text: 'Automatisch abgemeldet',
        type: 'warning'
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Performs the login
   */
  public async onLogin() {
    try {
      this.isLoading = true;
      const user = await this.loginAction({
        username: this.loginData.username,
        password: this.loginData.password
      });

      if (user) {
        await this.$router.push({ name: 'home_view', query: { defaultRoute: 'true' } });
      }
    } catch (err) {
      this.$notify({
        group: 'fahlke',
        title: 'Fehler',
        text: 'Login fehlgeschlagen',
        type: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }
}

