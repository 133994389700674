


























































































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Customer} from "@/models/customer";
import {Station} from "@/models/station";
import moment from "moment/moment";
import HeaderInformation from "@/components/cards/HeaderInformation.component.vue"

const StationStore = namespace('station');
const CustomerStore = namespace('customer');

enum CustomerFilterType {
  ALL = "ALL", MAINTENANCE = "MAINTENANCE", INVENTORY = "INVENTORY"
}

@Component({
  components: {
    HeaderInformation,
    EditStationComponent: () => import('./../components/EditStation.component.vue'),
    AmountCards: () => import('./../components/cards/AmountCards.component.vue'),
    MapInfoComponent: () => import('./../components/MapInfo.component.vue'),
    CustomerHeaderMachines: () => import('./../components/customer/CustomerHeaderMachines.component.vue')
  }
})
export default class CustomerDetailsView extends Vue {

  @CustomerStore.Action('loadCustomer')
  public loadCustomer!: (payload: { id: string }) => Promise<Customer>;

  @CustomerStore.Mutation('setSelectedCustomer')
  public setSelectedCustomer!: (customer: Customer) => void;

  @CustomerStore.Getter('selectedCustomer')
  public customer!: Customer;

  @StationStore.Action('loadCustomerStations')
  public loadCustomerStations!: (payload: { customer: Customer }) => Promise<Station[]>;

  @StationStore.Action('deleteStation')
  public deleteStation!: (payload: { id: string }) => void;

  public selectedFilter = CustomerFilterType.ALL;

  public filterItems: Array<{ text: string, value: CustomerFilterType }> = [{
    text: "Alle",
    value: CustomerFilterType.ALL
  }, {
    text: "Bestandsaufnahme",
    value: CustomerFilterType.INVENTORY
  }, {
    text: "Wartung",
    value: CustomerFilterType.MAINTENANCE
  }];

  public itemsPerPage = 50;
  public searchText: string = '';
  public headers: any[] = [
    {
      text: 'Station',
      value: 'name'
    },
    {
      text: 'Anschrift',
      value: 'address'
    },
    {
      text: 'PLZ',
      value: 'zipCode'
    },
    {
      text: 'Stadt',
      value: 'city'
    },
    {
      text: 'Kontaktperson',
      value: 'contactPerson'
    },
    {
      text: 'E-Mail',
      value: 'mail'
    },
    {
      text: 'Telefon',
      value: 'phone'
    },
    {
      text: 'Anzahl Maschinen',
      value: 'machines.length'
    },
    {
      text: 'Optionen',
      value: 'options',
      sortable: false
    }
  ];

  public showAddStationDialog: boolean = false;
  public showEditStationDialog: boolean = false;
  public showDeleteDialog: boolean = false;

  public stations: Station[] = [];
  public filteredStations: Station[] = [];
  public selectedStation?: Station;

  public loading: boolean = false;
  public showSnackBar: boolean = false;
  public timeout: number = 3000;
  public text: string = '';
  public toastColor: string = 'success';
  public isLoading: boolean = false

  public async mounted() {
    const customerId = this.$route.params.customerId;
    const customer = await this.loadCustomer({id: customerId});
    this.setSelectedCustomer(customer);
    this.stations = await this.loadCustomerStations({customer: this.customer});
    this.filterStations();
  }

  public filterStations() {
    if (this.selectedFilter === CustomerFilterType.ALL) {
      this.filteredStations = this.stations;
    } else if (this.selectedFilter === CustomerFilterType.INVENTORY) {
      this.filteredStations = this.stations.filter(station => station.inventoryActive);
    } else if (this.selectedFilter === CustomerFilterType.MAINTENANCE) {
      this.filteredStations = this.stations.filter(station => station.machines.find(machine => machine.maintenanceActive));
    }
  }

  public openStationDetails(station: Station) {
    this.$router.push({name: 'station_details_view', params: {stationId: station.id, customerId: this.customer.id}});
  }

  public openAddStationDialog() {
    this.showAddStationDialog = true;
  }

  public async closeDeleteDialog(confirm: boolean) {

    if(this.selectedStation) {
      if (confirm) {
        this.loading = true;

        try {
          await this.deleteStation({id: this.selectedStation.id});
          this.toastColor = 'success'
          this.text = 'Es wurde erfolgreich die Station gelöscht'
          this.showSnackBar = true;
          await this.refreshStations();
        } catch (e) {
          console.log('error', e)
          this.text = 'irgendwas ist schief gelaufen. ' + e;
          this.toastColor = 'red accent-2'
          this.showSnackBar = true;
        }

      }
    }

    this.showDeleteDialog = false;
    this.loading = false;
  }

  public async closeStationDialog(reloadData: boolean) {
    if (reloadData) {
      this.loading = true;
      this.showSnackBar = true;
      this.stations = await this.loadCustomerStations({customer: this.customer});
      this.filterStations();
      this.text = 'Es wurde erfolgreich eine neue Station hinzugefügt'
    }
    this.showAddStationDialog = false;
    this.loading = false;
  }



  public openEditStationDialog(station: Station) {
    this.selectedStation = station;
    this.showEditStationDialog = true;
  }

  public openDeleteDialog(station: Station) {
    this.selectedStation = station;
    this.showDeleteDialog = true;
  }



  public async closeEditStationDialog(reloadData: boolean) {
    if (reloadData) {
      this.showSnackBar = true;
      this.text = 'Die Änderungen wurden gespeichert.'
    }
    this.selectedStation = undefined;
    this.showEditStationDialog = false;
    this.stations = await this.loadCustomerStations({customer: this.customer});
    this.filterStations();
  }

  public goBackClicked() {
    this.$router.back();
  }

  public itemRowBackground(item: Station) {
    if (item.inventoryActive) {
      return 'red';
    } else if (item.machines.find(machine => {
      const currentDate = moment().endOf('day');
      const nextMaintenance = machine.nextMaintenance ? moment(machine.nextMaintenance, 'YYYY-MM-DD').toDate() : null;
      return nextMaintenance && nextMaintenance <= currentDate.toDate() && machine.maintenanceActive;
    })) {
      return 'yellow';
    }
    return '';
  }

  get machineCount() {
    let count = 0;
    this.stations.map(stations => {
      count += stations.machines.length;
    })
    return count;
  }

  public async refreshStations() {
    try {
      this.stations = await this.loadCustomerStations({customer: this.customer});
      this.filterStations();
    } catch(e) {
      console.log('error', e);
    }
  }
}
