import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {CustomerState, RootState} from '@/store/storeStateInterface';
import {Customer} from "@/models/customer";
import CustomerRepository from "@/api/CustomerRepository";

const store: CustomerState = {
    customers: [],
    selectedCustomer: undefined,
};
const customerRepository: CustomerRepository = RepositoryFactory.get('customer');

const actions: ActionTree<CustomerState, RootState> = {
    loadCustomer: async ({ commit }, payload: { id: string }): Promise<Customer> => {
        const response = await customerRepository.getCustomer(payload.id);
        return new Customer(response.data);
    },
    loadAllCustomers: async ({ commit }, payload: { isMaintenance: boolean }): Promise<Customer[]> => {
        const response = await customerRepository.getAllCustomers(payload.isMaintenance);
        const customers = response.data.map((data: any) => new Customer(data));
        commit('setCustomers', customers);
        return customers;
    },
    updateCustomer: async ({ commit }, payload: { customer: Customer }): Promise<Customer> => {
        const response = await customerRepository.updateCustomer(payload.customer);
        return new Customer(response.data);
    },
    createCustomer: async ({ commit }, payload: { customer: Customer }): Promise<Customer> => {
        const response = await customerRepository.createCustomer(payload.customer);
        return new Customer(response.data);
    },
    /*
    deleteCustomer: async ({ commit }, payload: { id: string }): Promise<Customer> => {
        const response = await customerRepository.deleteCustomer(payload.id);
        return response.data;
    }
    
     */
};

const mutations: MutationTree<CustomerState> = {
    setCustomers: (state: CustomerState, customers: any[]) => {
        state.customers = customers;
    },
    setSelectedCustomer: (state: CustomerState, customer: Customer) => {
        state.selectedCustomer = customer;
    }
};

const getters: GetterTree<CustomerState, RootState> = {
    customers: (state: CustomerState) => state.customers,
    selectedCustomer: (state: CustomerState) => state.selectedCustomer
};

const customerStore: Module<CustomerState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default customerStore;
