import {Station} from "@/models/station";

export class Customer {

    public id: string;
    public name!: string;
    public contactPerson!: string;
    public street!: string;
    public houseNo!: string;
    public zipCode!: string;
    public city!: string;
    public country!: string;
    public phone!: string;
    public mail!: string;

    public geoPosition: number[] = [];

    public stations: Station[] = [];

    constructor(customerData: any) {
        this.id = customerData._id;
        Object.assign(this, customerData);

        if (customerData.id) {
            this.id = customerData.id;
        }

        if (customerData.stations) {
            this.stations = customerData.stations.map((data: Partial<Station>) => new Station(data));
        }
    }
}
