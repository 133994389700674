

















































































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import {Machine} from "@/models/machine";
import {namespace} from "vuex-class";
import {Station} from "@/models/station";
import MachineRepository from "@/api/MachineRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";
import {Customer} from "@/models/customer";
import HeaderInformation from "@/components/cards/HeaderInformation.component.vue"
import {Maintenance} from "@/models/maintenance";
import MaintenanceRepository from "@/api/MaintenanceRepository";

const maintenanceRepository: MaintenanceRepository = RepositoryFactory.get('maintenance');

const machineRepository: MachineRepository = RepositoryFactory.get('machine');

const MachineStore = namespace('machine');
const StationStore = namespace('station');
const CustomerStore = namespace('customer');

@Component({
  components: {
    HeaderInformation,
    EditMachineComponent: () => import('./../components/EditMachine.component.vue'),
    EditChecklistComponent: () => import('./../components/EditChecklist.component.vue'),
    MachineTableRowComponent: () => import('../components/MachineTableRow.component.vue'),
    OfferView: () => import('./offer/Offer.view.vue'),
    StationHeader: () => import('./../components/station/StationHeader.component.vue'),
    ConfirmInventoryExportDialog: () => import('../components/dialog/ConfirmExportDialog.component.vue'),
    AmountCards: () => import('./../components/cards/AmountCards.component.vue'),
    MapInfoComponent: () => import('./../components/MapInfo.component.vue'),
    ConfirmChecklistExportDialog: () => import('../components/dialog/ConfirmExportDialog.component.vue'),
  }
})

export default class StationDetailsView extends Vue {

  @StationStore.Mutation('setSelectedStation')
  public setSelectedStation!: (station: Station) => void;

  @CustomerStore.Mutation('setSelectedCustomer')
  public setSelectedCustomer!: (customer: Customer) => void;

  @MachineStore.Action('loadStationMachines')
  public loadStationMachines!: (payload: { station: Station | undefined }) => Promise<Machine[]>;

  @StationStore.Action('loadStation')
  public loadStation!: (payload: { id: string }) => Promise<Station>;

  @CustomerStore.Action('loadCustomer')
  public loadCustomer!: (payload: { id: string }) => Promise<Customer>;

  @MachineStore.Action('deleteMachine')
  public deleteMachine!: (payload: { id: string }) => Promise<Machine>;

  @StationStore.Getter('selectedStation')
  public station!: Station;

  @MachineStore.Action("updateMachine")
  public updateMachine!: (payload: { machine: Machine }) => Promise<Machine>;

  @CustomerStore.Getter('selectedCustomer')
  public customer!: Customer;

  public currentStation?: Station;
  public machines: Machine[] = [];
  public machineMaintenances: Machine[] = [];
  public maintenances: Maintenance[] = [];
  public selectedMachine: Machine | undefined = new Machine({});

  public offerDialog = false;
  public inventoryDialog = false;
  public checklistDialog = false;

  public isLoading = false;

  public searchText: string = '';

  public selectedCheckboxes: any[] = [];
  public itemsPerPage = 10;

  public allSelected(payload: any) {
    if (!payload.value) {
      this.selectedCheckboxes = []
    } else {
      this.selectedCheckboxes = payload.items.map((item: Machine) => {
        return item;
      });
      // this.selectedCheckboxes = this.machines

    }
  }

  public headers: any[] = [
    {
      text: 'Auftragsnummer Fahlke',
      value: 'orderNo'
    }, {
      text: 'Serien-Nummer',
      value: 'serialNo'
    }, {
      text: 'TagNummer',
      value: 'tagNo'
    }, {
      text: 'Fahlke Antriebstyp',
      value: 'actuator'
    }, {
      text: 'Fahlke Bezeichnung Einheit',
      value: 'actuatorUnit'
    }, {
      text: 'Letzte Wartung',
      value: 'lastMaintenance'
    }, {
      text: 'Nächste Wartung ab',
      value: 'nextMaintenance'
    }, {
      text: 'Inspektionen nach Jahren',
      value: 'inspectionYears'
    }, {
      text: 'Inspektionsintervall',
      value: 'inspectionInterval'
    }, {
      text: 'Bestandsaufnahmeliste',
      value: 'checklist',
      sortable: false
    }, {
      text: 'Bilder',
      value: 'images',
      sortable: false
    }, {
    text: 'Dokument',
      value: 'documentPdf',
      sortable: false
    }, {

      text: 'Ist in Ordnung?',
      value: 'isOK',
      sortable: false
    }, {
      text: 'Wartung aktiv',
      value: 'maintenanceActive',
      sortable: false
    }, {
      text: 'Optionen',
      value: 'actions',
      sortable: false,
      align: 'center'
    }
  ];

  public dialogDelete: boolean = false;
  public showEditMachineDialog: boolean = false;
  public showCreateMachineDialog: boolean = false;
  public snackbar: boolean = false;
  public snackbarText: string = '';

  public async openCreateMachineDialog() {
    this.showCreateMachineDialog = true;
  }

  public async openEditMachineDialog(machine: Machine) {
    if (machine) {
      this.selectedMachine = machine;
    }
    this.showEditMachineDialog = true;
  }

  public async closeEditMachineDialog(edited: boolean) {
    if (edited) {
      this.snackbar = true;
      this.snackbarText = `Maschine erfolgreich gespeichert.`;
      await this.refreshMachines();
    }
    this.showCreateMachineDialog = false;
    this.showEditMachineDialog = false;
  }

  public async mahmut(edited: boolean) {
    console.log('edited', edited)
    if (edited) {
      /*if (this.selectedMachine) {
        await this.updateMachine({machine: this.selectedMachine});
      }*/
      await this.refreshMachines();
    }
  }

  public async mounted() {
    const stationId = this.$route.params.stationId;
    const customerId = this.$route.params.customerId;
    this.currentStation = await this.loadStation({id: stationId});
    this.setSelectedStation(this.currentStation);

    const customer = await this.loadCustomer({id: customerId});
    this.setSelectedCustomer(customer);

    await this.refreshMachines();
    this.getCountOfReadyMachines()
  }

  public async refreshMachines() {
    this.isLoading = true;
    this.machines = await this.loadStationMachines({station: this.currentStation});
    await this.filterMaintenances();
    this.isLoading = false;
  }

  public async filterMaintenances() {

    this.machines.forEach(abc => {
      if(abc.maintenances.length > 0) {
        abc.maintenances.forEach(maint => {
          this.maintenances.push(maint);
        })
      }
    });

    this.machineMaintenances = this.machines.filter(abc => {
      return abc.maintenances.length > 0
    });

  }

  public goBackClicked() {
    this.$router.back();
  }

  public deleteItem(item: any) {
    this.selectedMachine = item;
    this.dialogDelete = true
  }

  public async deleteItemConfirm() {
    if (this.selectedMachine) {
      await this.deleteMachine({id: this.selectedMachine.id});
      await this.refreshMachines()
    }
    this.closeDelete();
  }

  public closeDelete() {
    this.selectedMachine = undefined;
    this.dialogDelete = false
  }

  public async exportInventory(withImage: boolean) {
    this.isLoading = true;

    try {
      const response = await machineRepository.getChecklistPdf(
          this.selectedCheckboxes,
          this.currentStation,
          withImage
      );

      const blob = new Blob([response.data], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch (err) {
      this.$notify({
        group: 'fahlke',
        title: 'Fehler',
        text: 'Fehler beim Öffnen der PDF-Datei',
        type: 'error'
      });
    }
    this.isLoading = false;
  }

  public async closeInventoryDialog(arg: any) {
    if (arg !== undefined) {
      await this.exportInventory(arg)
    }
    this.inventoryDialog = false;
  }

  public openOfferDialog() {
    this.offerDialog = true
  }

  public closeOfferDialog() {
    this.offerDialog = false;
  }

  public openChecklistExportDialog() {
    this.checklistDialog = true
  }

  public async closeChecklistExportDialog(arg: any) {

    if (arg.withImages !== undefined) {
      await this.exportPDF(arg);
    }

    this.checklistDialog = false;
  }

  public async exportPDF(options: any) {
    try {

      const response = await maintenanceRepository.generateMultiplePDF(options.items, options);

      const blob = new Blob([response.data], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch (err) {
      this.$notify({
        group: 'fahlke',
        title: 'Fehler',
        text: 'Fehler beim Öffnen der PDF-Datei',
        type: 'error'
      });
    }
  }



  public async openInventoryDialog() {
    this.inventoryDialog = true
  }

  public openMachineDetails(machine: Machine) {
    this.$router.push({
      name: 'machine_details_view',
      params: {stationId: this.station.id, customerId: this.customer.id, machineId: machine.id}
    });
  }

  public getCountOfReadyMachines() {
    const m = this.machines.filter(machine => machine.maintenanceActive);
    return m.length
  }

}

