import Vue from 'vue';
import Vuex, {StoreOptions} from 'vuex';
import VuexPersistence from 'vuex-persist';
import {RootState} from './storeStateInterface';
import authStore from '@/store/auth.store';
import userStore from '@/store/user.store';
import customerStore from "@/store/customer.store";
import stationStore from "@/store/station.store";
import machineStore from "@/store/machine.store";
import settingsStore from "@/store/settings.store";
import maintenanceStore from "@/store/maintenance.store";

Vue.use(Vuex);

// add persistence plugin
const vuexLocal = new VuexPersistence<RootState>({
    key: 'FAHLKE',
    storage: window.localStorage,
    modules: ['auth', 'user', 'settings']
});

const store: StoreOptions<RootState> = {
    modules: {
        auth: {
            namespaced: true,
            ...authStore
        },
        user: {
            namespaced: true,
            ...userStore
        },
        customer: {
            namespaced: true,
            ...customerStore
        },
        station: {
            namespaced: true,
            ...stationStore
        },
        machine: {
            namespaced: true,
            ...machineStore
        },
        maintenance: {
            namespaced: true,
            ...maintenanceStore
        },
        settings: {
            namespaced: true,
            ...settingsStore
        }
    },
    plugins: [vuexLocal.plugin]
};

export default new Vuex.Store<RootState>(store);
