export enum MaintenanceElementStatus { notSet = "notSet", checkOkay = "checkOkay",
checkNotOkay = "checkNotOkay", notRequired = "notRequired" }
export enum MaintenanceElementCategory { condition = "condition", function = "function" }

export class MaintenanceListElement {
  public id?: string;
  public description!: string;
  public controlStatus!: MaintenanceElementStatus;
  public comment!: string;
  public category!: MaintenanceElementCategory;

  constructor(maintenanceListData: any) {
    this.id = maintenanceListData._id;
    Object.assign(this, maintenanceListData);

    if (maintenanceListData.id) {
      this.id = maintenanceListData.id;

    }
  }
}
