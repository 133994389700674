/**
 * Simple error wrapper class to normalize error cases
 */
export default class ApiError {

  /**
   * http status code
   */
  public status: number;

  /**
   * http status code
   */
  public code: number;

  /**
   * error message
   */
  public message: string;

  /**
   * error data
   */
  public data: any;

  constructor(status: number, message: string, data: any, code: number) {
    this.status = status;
    this.message = message;
    this.data = data;
    this.code = code;
  }

}
