import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';

export default class UserRepository extends EntityBaseRepository {

    public getAllUsers(): Promise<AxiosResponse> {
        return this.axiosClient.get('/user');
    }

    public getAllAppUsers(): Promise<AxiosResponse> {
        return this.axiosClient.get('/appUsers');
    }
}
