import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import {Customer} from "@/models/customer";

export default class CustomerRepository extends EntityBaseRepository {

    public getCustomer(id: string): Promise<AxiosResponse> {
        return this.axiosClient.get('/customer/' + id);
    }

    public getAllCustomers(isMaintenance: boolean): Promise<AxiosResponse> {
        return this.axiosClient.get(`/customer${isMaintenance ? "?isMaintenance=${isMaintenance}": ""}`);
    }

    public createCustomer(customer: Customer): Promise<AxiosResponse> {
        return this.axiosClient.post('/customer', customer);
    }

    public updateCustomer(customer: Customer): Promise<AxiosResponse> {
        return this.axiosClient.patch(`/customer/${customer.id}`, customer);
    }
}
