import {ChecklistElement, ElementStatus} from "@/models/checklistElement";
import {MachineImage} from "@/models/machineImage";
import {MachinePdf} from "@/models/machinePdf";
import {MachineImageType, Maintenance} from "@/models/maintenance";
import {Station} from "@/models/station";
import moment from "moment";
import AppUser from "@/models/appUser";
import User from "@/models/user";

export class Machine {

    public id: string;
    public orderNo!: string;
    public serialNo!: string;
    public tagNo!: string;
    public actuator!: string;
    public actuatorOption!: string;
    public actuatorUnit!: string;
    public lastMaintenance!: string; // date
    public nextMaintenance!: string;

    public nextMaintenanceDate?: Date;

    public appUser!: AppUser;
    public user!: User;

    public comment!: string;
    public inspectionYears!: number;
    public inspectionInterval!: number;
    public isComplete!: boolean;
    public isOK!: boolean;
    public checklist!: ChecklistElement[];
    public maintenanceActive!: boolean;

    public station?: Station;
    public maintenances: Maintenance[] = [];

    public imageMontageansicht: MachineImage[] = [];
    public imageSteuerung: MachineImage[] = [];
    public imageTypenschild: MachineImage[] = [];
    public imageBauteileSchrank: MachineImage[] = [];
    public imageDefekteBauteile: MachineImage[] = [];
    public imageMontageansichtNotRequired!: boolean;
    public imageSteuerungNotRequired!: boolean;
    public imageTypenschildNotRequired!: boolean;
    public imageBauteileSchrankNotRequired!: boolean;
    public imageDefekteBauteileNotRequired!: boolean;

    public imageMontageansichtComment!: string;
    public imageSteuerungComment!: string;
    public imageBauteileSchrankComment!: string;
    public imageDefekteBauteileComment!: string;
    public imageTypenschildComment!: string;

    public documentPdfs: MachinePdf [] = [];
    public documentElektroPlan?: MachinePdf;
    public documentFluidPlan?: MachinePdf;
    public documentPruefliste?: MachinePdf;



    constructor(machineData: any) {
        this.id = machineData._id;
        Object.assign(this, machineData);

        if (machineData.id) {
            this.id = machineData.id;
        }

        if (!machineData.inspectionInterval) {
            this.inspectionInterval = 1;
        }

        if (machineData.nextMaintenance) {
            this.nextMaintenanceDate = moment(machineData.nextMaintenance, 'YYYY-MM-DD').toDate();
        }

        if (machineData.appUser) {
            this.appUser = new AppUser(machineData.appUser);
        }

        if (machineData.user) {
            this.user = new User(machineData.user);
        }

        if (machineData.checklist) {
            if (typeof machineData.checklist == "string") {
                const checklistData = JSON.parse(machineData.checklist);
                this.checklist = checklistData.map((element: any) => new ChecklistElement(element));
                this.isOK = !this.checklist.some(element => element.elementStatus === ElementStatus.notOkay);
            } else {
                this.checklist = machineData.checklist;
                this.isOK = !this.checklist.some(element => element.elementStatus === ElementStatus.notOkay);
            }
        }

        if (machineData.maintenances) {
            this.maintenances = machineData.maintenances.map((data: any) => new Maintenance(data));
        }

        if (machineData.imageMontageansicht) {
            this.imageMontageansicht = machineData.imageMontageansicht.map((data: any) =>
                new MachineImage(data, MachineImageType.imageMontageansicht));
        } else {
            this.imageMontageansicht = [];
        }

        if (machineData.imageSteuerung) {
            this.imageSteuerung = machineData.imageSteuerung.map((data: any) =>
                new MachineImage(data, MachineImageType.imageSteuerung));
        } else {
            this.imageSteuerung = [];
        }

        if (machineData.imageTypenschild) {
            this.imageTypenschild = machineData.imageTypenschild.map((data: any) =>
                new MachineImage(data, MachineImageType.imageTypenschild));
        } else {
            this.imageTypenschild = [];
        }

        if (machineData.imageBauteileSchrank) {
            this.imageBauteileSchrank = machineData.imageBauteileSchrank.map((data: any) =>
                new MachineImage(data, MachineImageType.imageBauteileSchrank));
        } else {
            this.imageBauteileSchrank = [];
        }

        if (machineData.imageDefekteBauteile) {
            this.imageDefekteBauteile = machineData.imageDefekteBauteile.map((data: any) =>
                new MachineImage(data, MachineImageType.imageDefekteBauteile));
        } else {
            this.imageDefekteBauteile = [];
        }

        if (machineData.documentPdf) {
            this.documentPdfs = machineData.documentPdf.map((data: any) =>
                new MachinePdf(data));
        }


    }

    get hasImages(): boolean {
        return this.imageSteuerung.length > 0 || this.imageTypenschild.length > 0
            || this.imageMontageansicht.length > 0 || this.imageBauteileSchrank.length > 0 || this.imageDefekteBauteile.length > 0;
    }

    get hasPdfs(): boolean {
        return this.documentPdfs.length > 0;
    }


}
