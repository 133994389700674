import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import LoginView from '@/views/Login.view.vue';
import HomeView from '@/views/Home.view.vue';
import {RoutingPermissions} from '@/misc/RoutingPermissions';
import CustomersView from "@/views/Customers.view.vue";

import MapView from "@/views/Map.view.vue";
import MaintenanceToolView from "@/views/MaintenanceTool.view.vue";
import CustomerDetailsView from "@/views/CustomerDetails.view.vue";
import StationDetailsView from "@/views/StationDetails.view.vue";
import MachineDetailsView from "@/views/MachineDetails.view.vue";

const originalPush = VueRouter.prototype.push;
// @ts-ignore
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
// @ts-ignore
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login_view',
        component: LoginView
    },
    {
        path: '/home',
        name: 'home_view',
        component: HomeView,
        beforeEnter: (to, from, next) => RoutingPermissions.checkAccess(to, from, next),
        children: [{
            path: '/customers',
            name: 'customers_view',
            component: CustomersView
        },
            {
                path: '/customers/:customerId',
                name: 'customer_details_view',
                component: CustomerDetailsView
            },
            {
                path: '/customers/:customerId/stations/:stationId',
                name: 'station_details_view',
                component: StationDetailsView,
            },
            {
                path: '/customers/:customerId/stations/:stationId/machines/:machineId',
                name: 'machine_details_view',
                component: MachineDetailsView,
            },
            {
                path: '/map',
                name: 'map_view',
                component: MapView
            },
            {
                path: '/maintenanceTool',
                name: 'maintenance_tool_view',
                component: MaintenanceToolView
            },

            {
                path: '', // Default route
                redirect: {name: 'customers_view'}
            },
            {
                path: '*',
                redirect: {name: 'customers_view'}
            }]
    },
    {
        path: '*',
        redirect: {name: 'login_view'}
    }
];

const router = new VueRouter({
    routes
});

export default router;
