

























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ConfirmDialogComponent extends Vue {

  @Prop({ default: false })
  public showDialog!: boolean;

  @Prop({ default: '' })
  public title!: string;

  @Prop({ default: '' })
  public text!: string;

  public confirmed() {
    this.$emit('confirmed');
  }

  public closed() {
    this.$emit('closed');
  }

}

