






































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/user';
import {Permissions} from '@/misc/PermissionsEnum';
import {NavigationItemStorage, NavigationItemStorageInterface} from '@/misc/NavigationItemStorage';

const AuthStore = namespace('auth');
const SettingsStore = namespace('settings');

@Component({})
export default class HomeView extends Vue {

  @AuthStore.Getter('user')
  public user!: User;

  @SettingsStore.Getter('currentTitle')
  public currentTitle!: string;

  @SettingsStore.Mutation('setCurrentTitle')
  public setCurrentTitle!: (title: string) => void;

  @AuthStore.Mutation('clearLogin')
  public clearLogin!: () => void;

  public permissions = Permissions;

  public selectedNavigationItem: NavigationItemStorageInterface | null = null;
  public selectedTab: number = 0;
  public items = NavigationItemStorage;

  public drawer: boolean = false;
  public version: string = '';

  public mounted() {
    this.renderTabs();
    this.version = process.env.VUE_APP_VERSION!;
    this.setCurrentTitle('Fahlke Wartungsprogramm')
  }

  public renderTabs() {
    if (this.$route.query.defaultRoute) {
      this.selectedNavigationItem = this.items[0];
      this.$router.push({ name: this.selectedNavigationItem.defaultRoute });
    }
    this.$forceUpdate();
  }

  public logout() {
    this.clearLogin();
    this.$router.push({ name: 'login_view' });
  }

  public openNavigationItem(item: NavigationItemStorageInterface) {
    this.selectedNavigationItem = item;
    this.$router.push({ name: item.defaultRoute });
    this.selectedTab = 1;
    this.renderTabs();
  }
}

