import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';

export default class AuthRepository extends EntityBaseRepository {

    public login(username: string, password: string): Promise<AxiosResponse> {
        return this.axiosClient.post('/auth/login', { username, password });
    }

    public refreshToken(token: string): Promise<AxiosResponse> {
        return this.axiosClient.post('/auth/refreshToken', { token });
    }
}
