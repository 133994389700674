import axios, { AxiosInstance } from 'axios';
import ApiError from "@/api/ApiError";
import store from "@/store";
import router from "@/router";

/**
 * Base repository class
 */
export default class EntityBaseRepository {

    protected axiosClient!: AxiosInstance;
    protected baseURL: string = process.env.VUE_APP_BASE_URL!;

    constructor() {
        this.createHttpClient();
        this.setRequestInterceptors();
        this.setResponseInterceptors();
    }

    private createHttpClient() {
        this.axiosClient = axios.create({
            baseURL: this.baseURL,
            timeout: 1060000
        });
    }

    /**
     * Sets the request interceptors
     * @private
     */
    private setRequestInterceptors() {

        this.axiosClient.interceptors.request.use((config) => {
            if (store.getters[`auth/token`]) {
                config.headers.authorization = `Bearer ${store.getters[`auth/token`]}`;
            }
            return config;
        }, (error) => {
            throw Promise.reject(error);
        });
    }

    /**
     * Intercept response. Normalizes the response and handles certain error cases
     */
    private setResponseInterceptors() {
        this.axiosClient.interceptors.response.use(
          (response) => response,
          (error) => {
              if (error.response) {
                  if (error.response.status === 401) {
                      router.replace({ name: 'login_view' });
                  } else {
                      throw new ApiError(error.response.status, error.message, error.response.data.data, error.response.data.code);
                  }
              } else {
                  return Promise.reject(new Error());
              }
          }
        );
    }

}
