import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import { AuthState, RootState } from '@/store/storeStateInterface';
import User from '@/models/user';
import AuthRepository from '@/api/AuthRepository';

const store: AuthState = {
    user: undefined,
    token: undefined,
    lastUsername: undefined
};
const authRepository: AuthRepository = RepositoryFactory.get('auth');

const actions: ActionTree<AuthState, RootState> = {
    loginAction: async ({ commit }, payload: { username: string, password: string }): Promise<User> => {
        const response = await authRepository.login(payload.username, payload.password);
        const user = new User(response.data.user);
        commit('setUser', user);
        commit('setLastUsername', user.username);
        commit('setToken', response.data.token);
        return user;
    },
    refreshTokenAction: async ({ commit }, payload: { token: string }): Promise<any> => {
        const response = await authRepository.refreshToken(payload.token);
        if (response.data.token) {
            commit('setToken', response.data.token);
        }
        return response.data;
    }
};

const mutations: MutationTree<AuthState> = {
    setUser: (state: AuthState, user: User) => {
        state.user = user;
    },
    setLastUsername: (state: AuthState, username: string) => {
        state.lastUsername = username;
    },
    setToken: (state: AuthState, token: string) => {
        state.token = token;
    },
    clearLogin: (state: AuthState) => {
        state.token = undefined;
        state.user = undefined;
    }
};

const getters: GetterTree<AuthState, RootState> = {
    user: (state: AuthState) => new User(state.user),
    token: (state: AuthState) => state.token,
    lastUsername: (state: AuthState) => state.lastUsername
};

const authStore: Module<AuthState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default authStore;
