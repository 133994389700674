import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import {MachineImage} from "@/models/machineImage";
import {MachineImageType, Maintenance} from "@/models/maintenance";
import {Machine} from "@/models/machine";

export default class ImageRepository extends EntityBaseRepository {

    public deleteImage(image: MachineImage): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/image/${image.id}?imageType=${image.imageType}`);
    }

    public uploadImage(file: any, imageType: MachineImageType, data: { machine: Machine | null, maintenance: Maintenance | null }): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("image", file);
        let urlParam = '';
        if (data.machine) {
            formData.append("machine", data.machine.id);
            urlParam = `&machine=${data.machine.id}`;
        }
        if (data.maintenance) {
            formData.append("maintenance", data.maintenance.id);
            urlParam = `&maintenance=${data.maintenance.id}`;
        }
        console.log(formData)
        return this.axiosClient.post(`/image?imageType=${imageType}${urlParam}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
