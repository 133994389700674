





































































































































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Customer} from "@/models/customer";
import {Station} from "@/models/station";
import axios, {AxiosInstance} from 'axios';

const CustomerStore = namespace('customer');
const StationStore = namespace('station');
const SettingsStore = namespace('settings');

@Component({
  components: {
    EditCustomerComponent: () => import('./../components/EditCustomer.component.vue'),
    AmountCards: () => import('./../components/cards/AmountCards.component.vue'),
    MoneyCards: () => import('./../components/cards/MoneyCards.component.vue')
  }
})
export default class CustomersView extends Vue {
  public itemsPerPage = 50;
  public searchText: string = '';
  public headers: any[] = [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Anschrift',
      value: 'address'
    },
    {
      text: 'PLZ',
      value: 'zipCode'
    },
    {
      text: 'Stadt',
      value: 'city'
    },
    {
      text: 'Kontaktperson',
      value: 'contactPerson'
    },
    {
      text: 'E-Mail',
      value: 'mail'
    },
    {
      text: 'Telefon',
      value: 'phone'
    },
    {
      text: 'Optionen',
      value: 'options',
      sortable: false
    }
  ];

  public showAddCustomerDialog: boolean = false;
  public showEditCustomerDialog: boolean = false;

  public customers: Customer[] = [];
  public selectedCustomer?: Customer;

  public isLoading: boolean = false;
  public showSnackBar: boolean = false;
  public timeout: number = 3000;
  public text: string = '';

  @StationStore.Action('loadStationCount')
  public loadStationCount!: () => Promise<number>;

  @CustomerStore.Action('loadAllCustomers')
  public loadAllCustomers!: (payload: { isMaintenance: boolean }) => Promise<Customer[]>;

  public stationCount = 0;

  public async mounted() {
    this.customers = await this.loadAllCustomers({isMaintenance: false});
    this.stationCount = await this.loadStationCount();
  }

  public openCustomerDetails(customer: Customer) {
    this.$router.push({name: 'customer_details_view', params: {customerId: customer.id}});
  }

  public openAddCustomerDialog() {
    this.showAddCustomerDialog = true;
  }

  public async closeCustomerDialog(changed: boolean) {
    if (changed) {
      this.showSnackBar = true;
      this.isLoading = true;
      this.text = 'Es wurde erfolgreich eine neue Station hinzugefügt'
    }
    this.isLoading = false;
    this.showAddCustomerDialog = false;
    this.customers = await this.loadAllCustomers({isMaintenance: false});
  }

  public openEditCustomerDialog(customer: Customer) {
    this.selectedCustomer = customer;
    this.showEditCustomerDialog = true;
  }

  public async closeEditCustomerDialog(changed: boolean) {
    this.selectedCustomer = undefined;
    this.showEditCustomerDialog = false;
    if (changed) {
      this.showSnackBar = true;
      this.isLoading = true;
      this.text = 'Die Änderungen wurden gespeichert.'
    }
    this.isLoading = false;
    this.customers = await this.loadAllCustomers({isMaintenance: false});
  }
}
