import EntityBaseRepository from '@/api/EntityBaseRepository';
import { AxiosResponse } from 'axios';
import {Machine} from "@/models/machine";
import {MachinePdf, MachinePdfType} from "@/models/machinePdf";

export default class DocumentPdfRepository extends EntityBaseRepository {

    public deletePdf(pdf: MachinePdf): Promise<AxiosResponse> {
        return this.axiosClient.delete(`/pdf/${pdf.id}`);
    }

    public downloadPdf(pdf: MachinePdf): Promise<AxiosResponse> {
        return this.axiosClient.get(`/pdf/${pdf.id}`);
    }

    public getPDFDocument(pdf: MachinePdf): Promise<AxiosResponse> {
        return this.axiosClient.get(`/pdf/${pdf.id}`, {
            responseType: "blob"
        })
    }

    public uploadPdf(file: any, pdfType: MachinePdfType,  data: { machine: Machine | null }): Promise<AxiosResponse> {
        const formData = new FormData();
        formData.append("pdf", file);
        let urlParam = '';
        if (data.machine) {
            formData.append("machine", data.machine.id);
            urlParam = `&machine=${data.machine.id}`;
        }
        console.log(pdfType)

        return this.axiosClient.post(`/pdf?pdfType=${pdfType}${urlParam}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
