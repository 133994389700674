export enum ElementStatus { notSet = "notSet", okay = "okay",
notOkay = "notOkay", notAvailable = "notAvailable" }
export enum ElementCategory { optic = "optic", mechanic = "mechanic", electric = "electric" }
export enum ElementSelection { none = "none", oil = "oil", count = "count" }

export class ChecklistElement {
  public id?: string;
  public description!: string;
  public serialNoRequired!: boolean;
  public serialNo!: string;
  public elementStatus!: ElementStatus;
  public comment!: string;
  public category!: ElementCategory;
  public selection!: ElementSelection;
  public selectionValue!: string;
  public actuators!: string[];
  public selectionValues!: string[];
  public multiple!: boolean;
  public isCopiedElement?: boolean;

  public createdAt!: string;
  public updatedAt!: string;

  constructor(checklistData: any) {
    this.id = checklistData._id;
    Object.assign(this, checklistData);

    if (checklistData.id) {
      this.id = checklistData.id;

    }
  }
}
