import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RootState, SettingsState} from '@/store/storeStateInterface';

const store: SettingsState = {
    currentTitle: ""
};

const actions: ActionTree<SettingsState, RootState> = {
};

const mutations: MutationTree<SettingsState> = {
    setCurrentTitle: (state: SettingsState, title: string) => {
        state.currentTitle = title;
    },
};

const getters: GetterTree<SettingsState, RootState> = {
    currentTitle: (state: SettingsState) => state.currentTitle,
};

const settingsStore: Module<SettingsState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default settingsStore;
