export interface NavigationItemStorageInterface {
    title: string,
    icon: string,
    defaultRoute: string,
}

export const NavigationItemStorage: NavigationItemStorageInterface[] = [{
    title: 'Kundenübersicht',
    icon: 'mdi-account-group',
    defaultRoute: 'customers_view',
}, {
    title: 'Interaktive Karte',
    icon: 'mdi-map',
    defaultRoute: 'map_view',
}, {
    title: 'Bestandsaufnahme Tool',
    icon: 'mdi-wrench',
    defaultRoute: 'maintenance_tool_view',
}];
