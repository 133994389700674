


















































































































import {Component, Vue} from 'vue-property-decorator';
import {Machine} from "@/models/machine";
import {namespace} from "vuex-class";
import {Maintenance} from "@/models/maintenance";
import MaintenanceRepository from "@/api/MaintenanceRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";

const maintenanceRepository: MaintenanceRepository = RepositoryFactory.get('maintenance');

const MachineStore = namespace('machine');
const MaintenanceStore = namespace('maintenance');

@Component({
  components: {
    EditMachineComponent: () => import('../components/EditMachine.component.vue'),
    EditMaintenanceList: () => import('../components/EditMaintenanceList.component.vue'),
    MachineMaintenanceTableRowComponent: () => import('../components/MachineMaintenanceTableRow.component.vue'),
    MachineHeader: () => import('../components/machine/MachineHeader.component.vue'),
    ConfirmChecklistExportDialog: () => import('../components/dialog/ConfirmExportDialog.component.vue'),
  }
})
export default class MachineDetailsView extends Vue {
  @MachineStore.Action('loadMachine')
  public loadMachine!: (payload: { id: string }) => Promise<Machine>;

  @MaintenanceStore.Action('deleteMaintenance')
  public deleteMaintenance!: (payload: { id: string }) => Promise<Maintenance>;

  @MaintenanceStore.Action("updateMaintenance")
  public updateMaintenance!: (payload: { maintenance: Maintenance }) => Promise<Maintenance>

  public currentMachine: Machine | null = null;
  public selectedMaintenance: Maintenance | undefined;

  public selectedCheckboxes: any[] = [];

  public searchText: string = '';
  public itemsPerPage = 50;

  public exportChecklistExportDialog = false;

  public headers: any[] = [{
    text: 'Produktions-Nummer',
    value: 'productionNr'
  }, {
    text: 'Flap-Nummer',
    value: 'flapNr'
  }, {
    text: 'Wartungsdatum',
    value: 'maintenanceDate'
  }, {
    text: 'Kommentar',
    value: 'comment'
  }, {
    text: 'Wartungsbilder',
    value: 'images',
    align: 'center'
  }, {
    text: 'Optionen',
    value: 'actions',
    align: 'right'
  }];

  public maintenanceListDialog: boolean = false;
  public dialogDelete: boolean = false;
  public showEditMaintenanceDialog: boolean = false;
  public snackbar: boolean = false;
  public snackbarText: string = '';

  public async openEditMachineDialog(maintenance: Maintenance) {
    if (maintenance) {
      this.selectedMaintenance = maintenance;
    }
    this.showEditMaintenanceDialog = true;
  }

  public async closeEditMaintenanceDialog(edited: { saved: boolean }) {
    if (edited.saved) {
      try {
        await this.updateMaintenance({maintenance: this.selectedMaintenance!})
      } catch (e) {

      }
      this.snackbar = true;
      this.snackbarText = `Wartung erfolgreich gespeichert/erstellt.`;
    }
    this.showEditMaintenanceDialog = false;
  }

  public async mounted() {
    const machineId = this.$route.params.machineId;
    this.currentMachine = await this.loadMachine({id: machineId});
    // Show the newest maintenance on top
    this.currentMachine.maintenances.reverse();
  }

  public openChecklistExportDialog(item: Maintenance) {
    this.selectedMaintenance = item;
    this.exportChecklistExportDialog = true;
  }

  public async closeChecklistExportDialog(arg: any) {
    if (arg.withImages !== undefined) {
      await this.exportPDF(this.selectedMaintenance!, arg);
    }
    this.selectedMaintenance = undefined;
    this.exportChecklistExportDialog = false;
  }

  public goBackClicked() {
    this.$router.back();
  }

  public deleteItem(item: Maintenance) {
    this.selectedMaintenance = item;
    this.dialogDelete = true
  }

  public async exportPDF(item: Maintenance, options: any) {
    try {
      const response = await maintenanceRepository.generatePDF(item.id, options);

      const blob = new Blob([response.data], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch (err) {
      this.$notify({
        group: 'fahlke',
        title: 'Fehler',
        text: 'Fehler beim Öffnen der PDF-Datei',
        type: 'error'
      });
    }
  }

  public async deleteItemConfirm() {
    if (this.selectedMaintenance) {
      await this.deleteMaintenance({id: this.selectedMaintenance.id});
    }
    this.closeDelete();
  }

  public closeDelete() {
    this.selectedMaintenance = undefined;
    this.dialogDelete = false
  }
}

