import {ActionTree, GetterTree, Module, MutationTree} from 'vuex';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import {RootState, MaintenanceState} from '@/store/storeStateInterface';
import MaintenanceRepository from "@/api/MaintenanceRepository";
import {Maintenance} from "@/models/maintenance";

const store: MaintenanceState = {};
const maintenanceRepository: MaintenanceRepository = RepositoryFactory.get('maintenance');

const actions: ActionTree<MaintenanceState, RootState> = {
    updateMaintenance: async ({ commit }, payload: { maintenance: Maintenance }): Promise<Maintenance> => {
        const response = await maintenanceRepository.updateMaintenance(payload.maintenance);
        return new Maintenance(response.data);
    },
    deleteMaintenance: async ({ commit }, payload: { id: string }): Promise<Maintenance> => {
        const response = await maintenanceRepository.deleteMaintenance(payload.id);
        return response.data;
    }
};

const mutations: MutationTree<MaintenanceState> = {
};

const getters: GetterTree<MaintenanceState, RootState> = {
};

const maintenanceStore: Module<MaintenanceState, RootState> = {
    state: store,
    actions,
    mutations,
    getters
};

export default maintenanceStore;
