export enum Permissions {
    QS = 'qs',
    Storage = 'storage',
    Purchase = 'purchase',
    Sales = 'sales',
    Preperation = 'preperation',
    Accounting = 'accounting',
    Settings = 'settings'
}

export enum DetailPermissions {
    QS = 'QS',
    GoodsReceived = 'GoodsReceived',
    Invoices = 'Invoices',
    CheckInvoices = 'CheckInvoices',
    Complaint = 'Complaint',
    Consumption = 'Consumption',
    CalcPrice = 'CalcPrice',
    Orders = 'Orders',
    Sales = 'Sales',
    PartLists = 'PartLists',
    OrderPicking = 'OrderPicking',
    Booking = 'Booking',
    ErrorLists = 'ErrorLists',
    Accounting = 'Accounting'
}
